@import 'src/design-system/styles/typography.mixins';

.root {
  @include list-styles-reset;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 24px;
  width: 100%;
  height: 160px;

  color: var(--static-white);
  background: linear-gradient(90deg, rgba(0, 0, 0, .8) 17.37%, rgba(0, 0, 0, .608) 50.62%, rgba(0, 0, 0, .28664) 92.66%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  border-radius: 8px;


  &:hover {
    color: var(--static-white);
  }
}
